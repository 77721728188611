.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;

				&.fa-wave {
					width: 26px !important;
					margin: 0 !important;
					padding: 0 !important;
				}

				&.fa-wifi {
					width: 26px !important;
				}
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

}